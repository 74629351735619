import React from 'react';

const Banner: React.FC = () => {
  return (
    <div
      className="relative  bg-cover bg-center h-[80vh] w-full flex justify-center items-start flex-col pl-2 md:pl-6"
      style={{
        backgroundImage: "url('/image/c.jpg')", // Replace with your image path
      }}
    >
      {/* Top-left text */}
      <div className=" text-start">
        <h1 className="text-3xl md:text-5xl font-bold text-orange-500 pb-5">
          BANKING WITHOUT BORDERS
        </h1>
      </div>

      {/* Bottom-right text */}
      <div className="text-start">
        <p className="text-white text-xl md:text-2xl">
          move money fast and securely
        </p>
      </div>
    </div>
  );
};

export default Banner;
